import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"

class BlogTagTemplate extends Component {
  render() {
    const { data } = this.props

    return (
      <Layout>
        <div className="index" id="blog">

        {data.wordpressTag ? (
          <div className="row mt-5">
            <div className="col-12">
              <h1 className="h2">Resultaten voor <span className="primary">{data.wordpressTag.name}</span></h1>
            </div>
          </div>
        ) : ''}

        {data.allWordpressPost.edges && data.allWordpressPost.edges.map(({ node }) => (
          <div className="row mt-5 content" key={`blog-${node.wordpress_id}`}>
            <div className="col-lg-4 col-image">
              <Link to={`/blog/${node.slug}/`} title={node.title}>
                <img src={node.better_featured_image.source_url} className="img-fluid img-block" alt={node.title} />
              </Link>
            </div>
            <div className="col-lg-8 col-text">
              <h3 className="subtitle">{node.wps_subtitle}</h3>
              <h2 className="title primary my-2">{node.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: node.excerpt }}></div>
              <p>
                <Link to={`/blog/${node.slug}/`} className="">Lees meer...</Link>
              </p>
            </div>
          </div>
        ))}

        </div>
      </Layout>
    )
  }
}

export default BlogTagTemplate

export const query = graphql`
  query currentBlogTagQuery($id: String!, $tag: String!) {
    wordpressTag(id: { eq: $id }) {
      id
      wordpress_id
      name
      slug
    }
    allWordpressPost(filter: {tags: {elemMatch: {slug: {eq: $tag }}}}) {
      edges {
        node {
          id
          wordpress_id
          title
          wps_subtitle
          better_featured_image {
            source_url
          }
          excerpt
          slug
          link
        }
      }
    }
  }
`
